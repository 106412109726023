<template>
  <DashboardContainer>
    <counter-tabs />
    <GraphGrid1 />

    <!-- <div class="row">
            <div class="col-md-9"></div>
            <div class="col-md-3"><SoldSection /></div>
        </div> -->

    <!-- <leads-graph v-if="$storage.get('auth').user.user_type != 2"/> -->
    <GraphGrid2 />
    <!-- <CountersGrid v-if="$storage.get('auth').user.user_type != 2" /> -->

    <!-- <div
      class="saleperson-right"
      v-if="$storage.get('auth').user.user_type == 2"
    >
      <appointments :employeesList="allEmployees" :defaultAssignTo="assignTo" />
      <tasks :employeesList="allEmployees" :defaultAssignTo="assignTo" />
      <notes />
    </div> -->
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import CounterTabs from "../components/dashboard/CounterTabs";
//import SoldSection from '../components/dashboard/SoldSection'
import axios from "axios";
//import LeadsGraph from '../components/dashboard/LeadsGraph'
import GraphGrid1 from "../components/dashboard/GraphGrid1";
import GraphGrid2 from "../components/dashboard/GraphGrid2";
// import Appointments from "../components/leads/Appointments";
// import Tasks from "../components/leads/Tasks";
// import Notes from "../components/leads/Notes";
//import $ from "jquery";
//import axios from "axios";
import firebase from "firebase";
export default {
  name: "Dashboard",
  data() {
    return {
      allEmployees: [],
      assignTo: "",
      //LDetail: {},
      manager_name: "",
    };
  },
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    subscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }
          console.log(`"${topic}" is subscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
  },
  components: {
    DashboardContainer,
    CounterTabs,
    GraphGrid1,
    //LeadsGraph,
    GraphGrid2,
    // Appointments,
    // Tasks,
    // Notes,
  },
  mounted() {
    let v = this;
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(function () {
        return messaging.getToken();
      })
      .then(function (token) {
        console.log(token);
        if (token) {
          //save token in db
          axios
            .post(
              "update_token/",
              { token: token },
              {
                headers: {
                  Authorization: "Bearer " + v.$storage.get("auth").token,
                },
              }
            )
            .then((res) => {
              if (res.data.success == true) {
                console.log("token");
              }
            })
            .catch((err) => {
              console.log(err);
            });
          if (v.$storage.get("auth").user.user_type == 1) {
            v.subscribeTokenToTopic(
              token,
              "manager_" +
                v.$storage.get("auth").user.id +
                "_" +
                v.$storage.get("auth").user.dealer_id
            );
            v.subscribeTokenToTopic(
              token,
              "manager_" + v.$storage.get("auth").user.id
            );
           
          } else {
            v.subscribeTokenToTopic(
              token,
              "agent_" + v.$storage.get("auth").user.id
            );
          }
           v.subscribeTokenToTopic(token, "test");
        }
      })
      .catch(function (err) {
        console.log("User Chat Token Error" + err);
      });
  },
};
</script>